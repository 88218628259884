import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Color, Size } from '@app/presentation/types/ui.types';

@Component({
  selector: 'app-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss',
})
export class IconComponent {
  @Input() icon: string;
  @Input() color: Color;
  @Input() size: Size = 'md';
}
